import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import WordLimit from 'react-word-limit'

import './style.scss'

const DebatesBox = ({
  widthClass,
  background,
  dateHour,
  title,
  description,
  buttonUrl,
  buttonText,
  wordLimit,
}) => (
  <div className={widthClass}>
    <div
      className="debateBox"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="info">
        <div className="showBefore">
          <span>{dateHour}</span>
          <h2>{title}</h2>
        </div>
        <div className="showAfter">
          <span>{dateHour}</span>
          <h2>{title}</h2>
          <p><WordLimit limit={wordLimit}>{description}</WordLimit></p>
          <Link to={buttonUrl} className="button orange center">
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  </div>
)

DebatesBox.propTypes = {
  widthClass: PropTypes.string,
  background: PropTypes.string,
  dateHour: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  wordLimit: PropTypes.string,
}

DebatesBox.defaultProps = {
  widthClass: 'col-12 col-lg-3',
  background: '',
  dateHour: '',
  title: '',
  description: '',
  buttonUrl: '',
  buttonText: '',
  wordLimit: '20',
}

export default DebatesBox
